import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'src/components/PrivateRoute';
import PhaseRecipeLayout from 'src/components/phase-and-function/phases/layouts/PhaseRecipeLayout';
import NotFoundPage from 'src/pages/404';

const PhaseRecipe = () => (
    <Router>
        <PrivateRoute path="/recipe/:phaseName/:recipeId" component={PhaseRecipeLayout} />
        <NotFoundPage default />
    </Router>
);

export default PhaseRecipe;
